var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.show)?_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"height":"50vh"}},[_c('b-spinner',{attrs:{"label":"Spinning"}})],1):_vm._e(),(!_vm.show)?_c('b-tabs',{attrs:{"horizontal":"","nav-wrapper-class":"nav-horizontal"}},[_c('b-card',[_c('b-form',[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Заголовок","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Product name-default"}},[_vm._v("Заголовок")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Заголовок"},model:{value:(_vm.news.title),callback:function ($$v) {_vm.$set(_vm.news, "title", $$v)},expression:"news.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,581885626)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Товары","rules":"nullable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Product name-default"}},[_vm._v("Товары")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Товары"},model:{value:(_vm.news.product_ids),callback:function ($$v) {_vm.$set(_vm.news, "product_ids", $$v)},expression:"news.product_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1753390448)})],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Описание","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-card-code',{attrs:{"title":"Описание"},scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeBubble)+" ")]},proxy:true}],null,true)},[_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.news.description),callback:function ($$v) {_vm.$set(_vm.news, "description", $$v)},expression:"news.description"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1137605106)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Фото","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Quantity-default"}},[_vm._v("Фото")]),_c('div',{staticClass:"dropbox"},[_c('input',{staticClass:"input-file",attrs:{"type":"file","name":_vm.uploadFieldName,"accept":"image/*"},on:{"change":_vm.previewImage}}),_c('p',[_vm._v(" Drag your file(s) here to begin"),_c('br'),_vm._v(" or click to browse ")])]),_c('img',{attrs:{"height":"200px","src":("" + (_vm.preview || _vm.news.photo))}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1061836047)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"w-100 mt-3",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.edit($event)},"disabled":_vm.addStatus}},[_vm._v(" Изменить ")])],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }